import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function RatesPage() {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h2" align="center" gutterBottom>
        Download Our Rates
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" paragraph>
        We provide competitive pricing across all our services. Download our full rate sheet below.
      </Typography>
      <Box textAlign="center">
        <Button 
          variant="contained" 
          color="primary" 
          href="/assets/trw-contracting-rates.pdf"
          download
        >
          Download Rates PDF
        </Button>
      </Box>
    </Box>
  );
}

export default RatesPage;
