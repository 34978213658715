import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';

function Footer() {
  return (
    <Box sx={{ bgcolor: '#333333', color: '#ffffff', py: 4 }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={4}>
        <Typography variant="h6">About TRW Contracting Group</Typography>
          <Typography variant="body2" paragraph>
            We are a leading contracting group delivering world-class services in construction, engineering, IT, and consulting.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6">Quick Links</Typography>
          <Link href="/" color="inherit" underline="hover">Home</Link><br />
          <Link href="/services" color="inherit" underline="hover">Services</Link><br />
          <Link href="/projects" color="inherit" underline="hover">Projects</Link><br />
          <Link href="/rates" color="inherit" underline="hover">Rates</Link><br />
          <Link href="/contact" color="inherit" underline="hover">Contact Us</Link>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6">Contact Information</Typography>
          <Typography variant="body2">
            7305 SW Pecan Meadow Dr <br />
            Phone: (909) 801-4282 <br />
            Email: <Link href="mailto:info@trwcontractinggroup.com" color="inherit">info@trwcontractinggroup.com</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;