import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ServiceCard from '../components/ServiceCard';

// Placeholder images
const placeholderImageService = 'https://picsum.photos/400/300';

function ServicesPage() {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h2" align="center" gutterBottom>
        Our Services
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" paragraph>
        We provide a wide range of services to meet the needs of our clients, from construction and engineering to IT solutions and consulting.
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Construction" description="Complete construction and infrastructure solutions, from planning to execution." imageUrl={placeholderImageService}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="IT Solutions" description="Innovative IT services, including cloud computing, cybersecurity, and data solutions." imageUrl={placeholderImageService} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Consulting" description="Strategic consulting services to help clients meet their project goals and objectives." imageUrl={placeholderImageService} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Supply Chain Management" description="Optimized supply chain management services for complex projects." imageUrl={placeholderImageService} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Engineering" description="Full engineering services for infrastructure and systems design." imageUrl={placeholderImageService} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceCard title="Program Management" description="Experienced program management services for large-scale projects." imageUrl={placeholderImageService} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ServicesPage;
