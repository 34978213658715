import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function ConstructionPage() {
  return (
    <Container>
      <Box sx={{ padding: '50px 0' }}>
        <Typography variant="h3" gutterBottom>
          Construction & Infrastructure Development
        </Typography>
        <Typography variant="body1" paragraph>
          TRW Contracting Group provides large-scale construction and infrastructure development services, specializing in both public and private sector projects. Our team is equipped to handle everything from building federal facilities to roads and public infrastructure. We take pride in delivering projects on time and within budget, while maintaining the highest standards of quality and safety.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Our Construction Services Include:
        </Typography>
        <ul>
          <li>Federal and public building construction</li>
          <li>Roads and highways development</li>
          <li>Infrastructure for water, sewage, and utilities</li>
          <li>Residential and commercial development</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          NAICS Codes:
        </Typography>
        <ul>
          <li>236220: Commercial and Institutional Building Construction</li>
          <li>237310: Highway, Street, and Bridge Construction</li>
          <li>237110: Water and Sewer Line and Related Structures Construction</li>
          <li>236115: New Single-Family Housing Construction (except For-Sale Builders)</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Product and Service Codes (PSC):
        </Typography>
        <ul>
          <li>Y1AA: Construction of Office Buildings</li>
          <li>Y1AZ: Construction of Other Administrative Facilities and Service Buildings</li>
          <li>Y1LB: Construction of Highways, Roads, Streets, Bridges, and Railways</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Why Choose TRW Contracting Group?
        </Typography>
        <Typography variant="body1" paragraph>
          Our extensive experience and commitment to excellence make us the ideal partner for your next construction project. We adhere to strict safety protocols and ensure all our projects meet environmental regulations. Our team collaborates closely with clients to meet their unique needs, delivering results that exceed expectations.
        </Typography>
      </Box>
    </Container>
  );
}

export default ConstructionPage;
