import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function EnergyPage() {
  return (
    <Container>
      <Box sx={{ padding: '50px 0' }}>
        <Typography variant="h3" gutterBottom>
          Energy & Environmental Services
        </Typography>
        <Typography variant="body1" paragraph>
          At TRW Contracting Group, we believe in delivering sustainable energy solutions and environmental services to enhance the future. Our team provides cutting-edge services in green energy development, energy efficiency, and environmental management that comply with all government and environmental regulations.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Our Energy & Environmental Services Include:
        </Typography>
        <ul>
          <li>Renewable Energy Solutions (Solar, Wind, etc.)</li>
          <li>Energy Efficiency Audits and Implementation</li>
          <li>Environmental Impact Assessments</li>
          <li>Sustainable Infrastructure Development</li>
          <li>Green Building Certifications and Consulting</li>
          <li>Water and Waste Management Services</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          NAICS Codes:
        </Typography>
        <ul>
          <li>541620: Environmental Consulting Services</li>
          <li>221114: Solar Electric Power Generation</li>
          <li>237130: Power and Communication Line and Related Structures Construction</li>
          <li>238220: Plumbing, Heating, and Air-Conditioning Contractors (Energy Efficiency Solutions)</li>
          <li>541330: Engineering Services</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Product and Service Codes (PSC):
        </Typography>
        <ul>
          <li>F108: Environmental Systems Protection Services</li>
          <li>J045: Maintenance, Repair, and Rebuilding of Equipment – Plumbing, Heating, and Waste Disposal Equipment</li>
          <li>R425: Engineering and Technical Services</li>
          <li>S114: Water and Environmental Systems Maintenance</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Why Choose TRW Energy & Environmental Services?
        </Typography>
        <Typography variant="body1" paragraph>
          TRW Contracting Group is dedicated to delivering environmentally responsible and energy-efficient solutions. Our expertise in sustainable energy, compliance with green building standards, and focus on minimizing environmental impact make us the perfect partner for government and public sector energy projects.
        </Typography>
      </Box>
    </Container>
  );
}

export default EnergyPage;
