import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';

function ProjectCaseStudy({ title, description, imageUrl }) {
  return (
    <Card sx={{ maxWidth: 600, margin: "auto 0", height: "100%"}}>
      <CardMedia
        component="img"
        alt={title}
        height="140"
        image={imageUrl}
      />
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ProjectCaseStudy;
