import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function ConsultingPage() {
  return (
    <Container>
      <Box sx={{ padding: '50px 0' }}>
        <Typography variant="h3" gutterBottom>
          Consulting & Strategic Advisory Services
        </Typography>
        <Typography variant="body1" paragraph>
          TRW Contracting Group offers expert consulting and strategic advisory services to help government agencies and private companies manage complex projects. Our team of experienced consultants works closely with stakeholders to ensure efficient and effective project management, strategy development, and process optimization.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Our Consulting Services Include:
        </Typography>
        <ul>
          <li>Project Management and Oversight</li>
          <li>Business Process Optimization</li>
          <li>Defense and Civilian Infrastructure Consulting</li>
          <li>Government Contracting Advisory Services</li>
          <li>Strategic Planning and Risk Management</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          NAICS Codes:
        </Typography>
        <ul>
          <li>541611: Administrative Management and General Management Consulting Services</li>
          <li>541618: Other Management Consulting Services</li>
          <li>541690: Other Scientific and Technical Consulting Services</li>
          <li>541990: All Other Professional, Scientific, and Technical Services</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Product and Service Codes (PSC):
        </Typography>
        <ul>
          <li>R408: Program Management/Support Services</li>
          <li>R499: Other Professional Services</li>
          <li>B599: Special Studies/Analysis- Other</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Why Choose TRW Consulting Services?
        </Typography>
        <Typography variant="body1" paragraph>
          Our consultants are industry leaders with years of experience across a range of industries, including defense, energy, and infrastructure. We specialize in creating tailored strategies that deliver measurable results, ensuring that your project meets its goals on time and within budget.
        </Typography>
      </Box>
    </Container>
  );
}

export default ConsultingPage;
