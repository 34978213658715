import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function DefensePage() {
  return (
    <Container>
      <Box sx={{ padding: '50px 0' }}>
        <Typography variant="h3" gutterBottom>
          Defense & Aerospace Contracting Services
        </Typography>
        <Typography variant="body1" paragraph>
          TRW Contracting Group is committed to supporting national defense through our comprehensive range of defense and aerospace contracting services. Our expert teams are well-versed in the latest technologies and industry standards, providing innovative solutions to meet the unique needs of the defense sector.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Our Defense Services Include:
        </Typography>
        <ul>
          <li>Defense Manufacturing and Research & Development</li>
          <li>Aerospace Engineering and Maintenance</li>
          <li>Technology Solutions for Defense Operations</li>
          <li>Military Infrastructure Development</li>
          <li>Logistics and Supply Chain for Defense Projects</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          NAICS Codes:
        </Typography>
        <ul>
          <li>336411: Aircraft Manufacturing</li>
          <li>336414: Guided Missile and Space Vehicle Manufacturing</li>
          <li>541715: Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)</li>
          <li>561210: Facilities Support Services</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Product and Service Codes (PSC):
        </Typography>
        <ul>
          <li>J015: Maintenance, Repair, and Rebuilding of Equipment - Aircraft and Airframe Structural Components</li>
          <li>AC15: National Defense R&D Services; Defense-related activities</li>
          <li>K015: Modification of Equipment - Aircraft</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Why Choose TRW Defense & Aerospace Contracting?
        </Typography>
        <Typography variant="body1" paragraph>
          With a proven track record of success, TRW Contracting Group delivers cutting-edge solutions for defense and aerospace projects. We understand the critical importance of quality, precision, and security in the defense industry, and our services are designed to meet the high standards required by government contracts and military operations.
        </Typography>
      </Box>
    </Container>
  );
}

export default DefensePage;
