import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function SupplyChainPage() {
  return (
    <Container>
      <Box sx={{ padding: '50px 0' }}>
        <Typography variant="h3" gutterBottom>
          Supply Chain Management Services
        </Typography>
        <Typography variant="body1" paragraph>
          TRW Contracting Group offers comprehensive Supply Chain Management solutions that streamline procurement, logistics, and inventory processes for government agencies and businesses. Our supply chain services ensure the seamless coordination of materials, goods, and information across all stages of the supply chain, providing efficiency and transparency at every step.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Our Supply Chain Management Services Include:
        </Typography>
        <ul>
          <li>Procurement Management</li>
          <li>Logistics & Distribution Solutions</li>
          <li>Inventory Optimization & Forecasting</li>
          <li>Supplier Relationship Management</li>
          <li>Supply Chain Risk Mitigation</li>
          <li>Warehousing & Fulfillment Services</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          NAICS Codes:
        </Typography>
        <ul>
          <li>541614: Process, Physical Distribution, and Logistics Consulting Services</li>
          <li>493110: General Warehousing and Storage</li>
          <li>488510: Freight Transportation Arrangement</li>
          <li>423110: Industrial Machinery and Equipment Merchant Wholesalers</li>
          <li>425120: Wholesale Trade Agents and Brokers</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Product and Service Codes (PSC):
        </Typography>
        <ul>
          <li>R706: Logistics Support Services</li>
          <li>V003: Transportation/Travel/Relocation - Motor Pool and Packing/Crating</li>
          <li>W081: Lease or Rental of Transportation Equipment</li>
          <li>S203: Housekeeping - Packaging and Crating</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Why Choose TRW Supply Chain Management Services?
        </Typography>
        <Typography variant="body1" paragraph>
          Our supply chain expertise ensures efficient, cost-effective solutions that mitigate risk, reduce lead times, and improve inventory management. TRW Contracting Group’s comprehensive services are designed to handle complex logistics for both government and private sector clients, ensuring timely and effective project delivery.
        </Typography>
      </Box>
    </Container>
  );
}

export default SupplyChainPage;
