import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function ITPage() {
  return (
    <Container>
      <Box sx={{ padding: '50px 0' }}>
        <Typography variant="h3" gutterBottom>
          IT & Cybersecurity Services
        </Typography>
        <Typography variant="body1" paragraph>
          TRW Contracting Group provides advanced IT solutions and cybersecurity services tailored to meet the needs of government agencies and private sector clients. We specialize in secure cloud infrastructure, custom software development, and protecting critical information systems from cyber threats.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Our IT & Cybersecurity Services Include:
        </Typography>
        <ul>
          <li>Cloud Infrastructure & Services</li>
          <li>Cybersecurity Strategy and Implementation</li>
          <li>Custom Software Development</li>
          <li>Network Security and Risk Management</li>
          <li>Data Center Solutions and IT Infrastructure</li>
          <li>IT Consulting and Project Management</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          NAICS Codes:
        </Typography>
        <ul>
          <li>541512: Computer Systems Design Services</li>
          <li>541513: Computer Facilities Management Services</li>
          <li>541519: Other Computer Related Services</li>
          <li>518210: Data Processing, Hosting, and Related Services</li>
          <li>541690: Security Consulting Services</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Product and Service Codes (PSC):
        </Typography>
        <ul>
          <li>D310: IT and Telecom - Cyber Security and Data Backup</li>
          <li>D302: IT and Telecom - Systems Development</li>
          <li>D316: IT and Telecom - Telecommunications Network Management</li>
          <li>D399: IT and Telecom - Other IT and Telecommunications</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Why Choose TRW IT & Cybersecurity Services?
        </Typography>
        <Typography variant="body1" paragraph>
          With a focus on innovation, security, and reliability, TRW Contracting Group is the trusted partner for government agencies and businesses seeking cutting-edge IT solutions. Our cybersecurity services protect critical assets, while our cloud infrastructure and custom software development drive operational efficiency.
        </Typography>
      </Box>
    </Container>
  );
}

export default ITPage;
