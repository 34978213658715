import React from 'react';
import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function ServiceCard({ title, description, imageUrl, linkUrl }) {
  return (
    <Card sx={{ maxWidth: 345, margin: "auto", height: "100%" }}>
      <CardMedia component="img" alt={title} height="140" image={imageUrl} />
      <CardContent>
        <Typography variant="h5" gutterBottom>{title}</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {description}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={linkUrl}
          sx={{ textTransform: 'none' }}  // To prevent button text from being all uppercase
        >
          Learn More
        </Button>
      </CardContent>
    </Card>
  );
}

export default ServiceCard;
