import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ProjectCaseStudy from '../components/ProjectCaseStudy';

// Placeholder images
const placeholderImageProject = 'https://picsum.photos/500/350';


function ProjectsPage() {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h2" align="center" gutterBottom>
        Our Projects
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" paragraph>
        We are proud to showcase our successful projects, which demonstrate our commitment to excellence.
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <ProjectCaseStudy 
            title="Project Alpha"
            description="Successfully delivered a large-scale military infrastructure project."
            imageUrl={placeholderImageProject}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProjectCaseStudy 
            title="Project Beta"
            description="Provided IT infrastructure and cybersecurity services for a federal agency."
            imageUrl={placeholderImageProject}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProjectCaseStudy 
            title="Project Gamma"
            description="Engineered and managed a nationwide supply chain solution for a major retailer."
            imageUrl={placeholderImageProject}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProjectCaseStudy 
            title="Project Delta"
            description="Led the design and construction of a cutting-edge research facility."
            imageUrl={placeholderImageProject}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProjectsPage;
